<template>
  <div class="ourthinking">
    <div class="banner_container contactus_banner border_text_box">
      <div class="border_text">Our Thinking</div>
      <h1 class="border_text_title">我们的思考</h1>
    </div>
    <div class="bread_crumbs container_width">
      <span>您的位置：首页 > </span>
      <span class="current">我们的思考</span>
    </div>
    <div class="content_container">
        <div class="content_box border_text_box container_width">
            <h2 class="border_text">How can keep consumers?</h2>
            <h3 class="border_text_title">2022聚焦未来-品牌加速</h3>
            <div class="description">消费者为王。这就是数字经济的现实。对于品牌而言，影响深远。 最终，他们必须以新的方式与消费者互动，同时根据快速变化的需求，态度和行为重新定制他们的服务。</div>
            <div class="content_line"></div>
            <p>今天的首席营销官们（CMO）发现自己已经走到了品牌历程的十字路口。新冠疫情带来的短期直接影响已获得了广泛研究和深入报道。在疫情冲击下，营销预算大幅削减——近三分之二（62％）的CMO预测，公司营销预算将在未来12个月内减少或保持不变（2019年持此观点的CMO比例为41％）。</p>
            <p>不到四分之一的CMO认为疫情对公司的影响微乎其微，还有近十分之一的CMO认为疫情威胁到了公司生存。品牌内部的数字化转型正在加速，2020年上半年，电子商务、远程工作和在家学习盛行一时。</p>
            <img src="../assets/imgs/ourthinking/text_img_1.webp" alt="">
            <p class="newline">今天的首席营销官们（CMO）发现自己已经走到了品牌 历程的十字路口。新冠疫情带来的短期直接影响已获得了广泛研究和深入报道。在疫情冲击下，营销预算大幅 削减——近三分之二（62％）的CMO预测，公司营销预算将在未来12个月内减少或保持不变（2019年持此观点 的CMO比例为41％）。不到四分之一的CMO认为疫情对公司的影响微乎其微，还有近十分之一的CMO认为疫情威胁到了公司生存。品牌内部的数字化转型正在加速，2020年上半年，电子商务、远程工作和在家学习盛行一时。然而，人们对疫情带来的长期社会影响仍然知之甚少。我们的工作、购物、互动、学习、娱乐、健 身方式都 将发生看似微妙实则意义深远的变化。在后疫情时代，人们将重新设计办公环境、城市中心 和自己的住宅，社会环境也将随之转变。生活的方方面面都将改变，但对于变化的深度和持续时间，我们只能拭目以待。</p>
            <h2 class="border_text">Five strategies</h2>
            <h3 class="border_text_title">新锐CMO部署的五项战略</h3>
            <div class="description">资深效果营销团队，对Affiliate行业十年深耕，精通各种类型流量，以及Offers效果观察，我们有自有网盟 Yoyu Media ,一手流量，业务包括，CPA CPL CPI CPS 等诸多类型。</div>
            <div class="content1_box">
                <img src="../assets/imgs/ourthinking/content1_img_1.webp" alt="" v-bind:class="{ fadeinleft: flag }">
                <div class="content" v-bind:class="{ fadeinleft1: flag }">
                    <h3>1. 超级同理心：</h3>
                    <p class="content_newline">深入了解新老客户，根据情势的变化实时更新对消费者的理解（如通过第一方客户数据、电子商务和直接面向消费者的战略，以及增加CRM投资等）。这就要求营销人员利用准确的消费者情报，运用设计思想，从销售产品和服务全面转变为创造有用的体验和无处不在的实用服务。</p>
                    <div class="content_bottom">
                        <h3>2. 超级敏捷：</h3>
                        <p>为品牌赋予初创企业的执行力，利用消费者洞见迅速开发出新型理念、产品和服务，推动内容、创新与产品的发展。</p>
                    </div>
                </div>
            </div>
            <div class="content1_box m_content1_box">
                <div class="content"  v-bind:class="{ fadeinright1: flag1 }">
                    <h3>3. 超级协作：</h3>
                    <p>确保企业作为一个整体，在持续协调的过程 中采取统一行动，这就要求营销人员与公司 高管以及销售、研发、产品开发和企业生态 系统内的其他外部合作伙伴/供应商开展更 高级别的协作</p>
                    <h3>4. 超级整合：</h3>
                    <p>能够快速厘清产品组合，优化整合更具韧性 的大型品牌，提高品牌在经济下行期的生存 能力，同时不断寻求机遇，通过战略性并购 弥补能力差距。</p>
                    <h3>5. 超级透明：</h3>
                    <p>确保品牌运作涉及的所有要素都能经受严 格的外部审查，包括供应链、员工福利和消 费者参与等审查指标。</p>
                    <h3>6. 新锐CMO凭借这些战略更胜一筹</h3>
                    <p>我们的分析表明，新锐CMO部署这些战略的方式明显区别于对未来信心不足的普通CMO。</p>
                </div>
                <img src="../assets/imgs/ourthinking/content1_img_2.webp" alt=""  v-bind:class="{ fadeinright: flag1 }">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import '../assets/scss/animation.scss'
import '../assets/scss/communal.scss'
export default {
    name: "ourthinking",
    data(){
        return{
            flag:false,
            flag1: false
        }
    },
    mounted(){
        //获取页面可视化高度
        window.addEventListener("scroll",()=>{ 
        //获取页面滚动的高度
            let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            if(scrollTop > 1900){
                this.flag = true;
            }
            if(scrollTop > 2600){
                this.flag1 = true;
            }
        }) 
    },
}
</script>

<style lang="scss">
@import '../assets/scss/layout.scss';
.ourthinking {
    .contactus_banner {
        background: url('../assets/imgs/ourthinking/banner.webp') 50% 50%;
        background-size: cover;
        .border_text_title {
          color: #fff
        }
    }
    .content_container {
        .content_box {
            .description {
                margin-bottom: 50px;
            }
            p {
                font-size: 20px;
                text-align: justify;
                color: #464949;
                line-height: 30px;
                margin-bottom: 40px;
            }
            img {
                width: 100%;
                margin-bottom: 60px;
            }
            .newline {
                margin-bottom: 120px;
            }
            .content1_box {
                @include flexrl();
                img {
                    width: 59%;
                    opacity:0;
                    animation-fill-mode: both;
                }
                .content {
                    width: 33%;
                    font-size: 20px;
                    color: #464949;
                    opacity: 0;
                    animation-fill-mode: both;
                    .content_newline {
                        margin-bottom: 100px;
                    }
                    h3 {
                        text-align: left;
                    }
                    .content_bottom {
                        width: 85%;
                        float: right;
                        position: relative;
                    }
                    .content_bottom::after {
                        content: '“';
                        position: absolute;
                        -webkit-text-stroke: 1px #c5c5c5;
                        font-size: 128px;
                        color: #f7f7f7;
                        top: -60px;
                        left: -65px;
                    }
                }
            }
        }
    }
}
.fadeinleft {
	-webkit-animation: fade-in-left 1.6s ;
	animation: fade-in-left 1.6s ;
    animation-fill-mode: both;
}
.fadeinleft1 {
	-webkit-animation: fade-in-left 1.6s ;
	animation: fade-in-left 1.6s ;
    animation-delay: 0.5s;
    animation-fill-mode: both;
}
.fadeinright {
	-webkit-animation: fade-in-right 1.6s ;
	animation: fade-in-right 1.6s ;
    animation-fill-mode: both;
}
.fadeinright1 {
	-webkit-animation: fade-in-right 1.6s ;
	animation: fade-in-right 1.6s ;
    animation-delay: 0.5s;
    animation-fill-mode: both;
}
@media screen and (max-width: 1160px){}
@media screen and (max-width: 820px){}
@media screen and (max-width: 768px){
    .ourthinking {
        .content_container {
            .content_box {
                .content1_box {
                    flex-direction: column;
                    img {
                        width: 100%;
                    }
                    .content {
                        width: 100%;
                    }
                }
                .m_content1_box {
                    flex-direction: column-reverse;
                }
            }
        }
    }
}
</style>